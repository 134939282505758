export default class DwdTheme {
    static init() {
        const mainNav = document.querySelector('.header--bottom');
        if (!mainNav) {
            console.error('main navigation not found');
            return;
        }

        const handleScroll = () => {
            if (window.scrollY >= 125) {
                mainNav.classList.add('scroll');
            } else {
                mainNav.classList.remove('scroll');
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check
    }
}
