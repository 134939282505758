import {FROM_TABLET, TILL_TABLET} from "../abstracts/variables";

export default class DwdNavigation {
    static init() {
        this.initHamburgerToggle();
        this.initMobileMenu();
        this.initDesktopMenu();
    }

    static initHamburgerToggle() {
        this.toggleElement('.nav-toggle-collapsed', '.nav-mobile--collapsed', 'expand', 'nav-mobile--expand', 'nav-mobile--collapsed');
    }

    static toggleElement(toggleSelector, targetSelector, toggleClass, addClass, removeClass) {
        const toggleElement = document.querySelector(toggleSelector);
        const targetElement = document.querySelector(targetSelector);

        if (toggleElement && targetElement) {
            toggleElement.addEventListener('click', () => {
                toggleElement.classList.toggle(toggleClass);
                targetElement.classList.toggle(addClass);
                targetElement.classList.toggle(removeClass);
            });
        }
    }

    static initMobileMenu() {
        this.initMenu('.nav-mobile > .menu-main-menu-container > ul');
    }

    static initDesktopMenu() {
        this.initMenu('.nav--desktop > .menu-main-menu-container > ul');
    }

    static initMenu(menuSelector) {
        const menu = document.querySelector(menuSelector);
        if (menu) {
            const menuItems = menu.querySelectorAll('li.menu-item-has-children > a');

            menuItems.forEach(item => {
                item.addEventListener('click', this.handleMenuClick);
            });
        }
    }

    static handleMenuClick(event) {
        event.preventDefault();
        const parentLi = event.target.parentElement;

        const siblings = parentLi.parentElement.children;
        for (let sibling of siblings) {
            if (sibling !== parentLi && sibling.classList.contains('menu-item-has-children')) {
                sibling.classList.remove('expand');
            }
        }

        parentLi.classList.toggle('expand');
    }
}
