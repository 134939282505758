import DwdTheme from './theme/theme';
import DwdNavigation from './components/navigation';
import DwdSwiper from "./components/dwd-slider";
import DwdCursor from "./components/custom-cursor";
import DwdConsultation from "./components/consultation";
import DwdFilter from "./components/filter";

document.addEventListener('DOMContentLoaded', (e) => {
    DwdSwiper.init();
    DwdTheme.init();
    DwdNavigation.init();
    DwdCursor.init();
    DwdConsultation.init();
    DwdFilter.init();
});

