import { gsap } from "gsap";
import { FROM_TABLET } from "../abstracts/variables";

export default class DwdCursor {
    static cursors = [
        {
            element: ".cursor--cases",
            items: ".cases__item",
            updateDuration: 0.6,
            ease: "power2.out",
            listenToHover: false,
        },
        {
            element: ".cursor--footer--red",
            items: ".footer",
            updateDuration: 0.1,
            ease: "power2.out",
            listenToHover: true,
        },
        {
            element: ".cursor--footer--white",
            items: ".footer",
            updateDuration: 0.5,
            ease: "sine",
            listenToHover: true,
        },
        {
            element: ".cursor--footer--blue",
            items: ".footer",
            updateDuration: 1.1,
            ease: "sine",
            listenToHover: true,
        },
    ];

    static activeCursors = [];

    static init() {
        gsap.set(this.cursors.map(c => c.element), { xPercent: -50, yPercent: -50, opacity: 0 });
        this.refreshCursors();
        window.addEventListener("resize", this.refreshCursors.bind(this));
    }

    static showCursor(cursor) {
        gsap.to(cursor, { duration: 0.3, opacity: 1 });
    }

    static hideCursor(cursor) {
        gsap.to(cursor, { duration: 0.3, opacity: 0 });
    }

    static updateCursor(e, cursor, updateDuration, ease) {
        const angle = (e.movementX + e.movementY) * 0.8;
        gsap.to(cursor, {
            duration: updateDuration,
            x: e.clientX,
            y: e.clientY,
            rotation: angle,
            ease: ease,
        });
    }

    static activateCursor(cursorElement, itemsSelector, duration, ease, listenToHover) {
        const items = document.querySelectorAll(itemsSelector);
        items.forEach(item => {
            item.addEventListener("mousemove", (e) => {
                if (e.target.tagName.toLowerCase() === 'a' && listenToHover) {
                    cursorElement.classList.add('active');
                } else if (cursorElement.classList.contains('active')) {
                    cursorElement.classList.remove('active');
                }
                this.showCursor(cursorElement);
                this.updateCursor(e, cursorElement, duration, ease);
            });

            item.addEventListener("mouseleave", () => {
                this.hideCursor(cursorElement);
                cursorElement.classList.remove('active');
            });
        });
        this.activeCursors.push({ cursorElement, items });
    }

    static deactivateCursor() {
        this.activeCursors.forEach(({ cursorElement, items }) => {
            items.forEach(item => {
                item.removeEventListener("mousemove", this.updateCursor);
                item.removeEventListener("mouseleave", () => {
                    this.hideCursor(cursorElement);
                    cursorElement.classList.remove('active');
                });
            });
        });
        this.activeCursors = [];
    }

    static refreshCursors() {
        if (window.matchMedia("(min-width: " + FROM_TABLET + "px)").matches) {
            this.cursors.forEach(({ element, items, updateDuration, ease, listenToHover }) => {
                const cursorElement = document.querySelector(element);
                this.activateCursor(cursorElement, items, updateDuration, ease, listenToHover);
            });
        } else {
            this.deactivateCursor();
        }
    }
}