import {FROM_TABLET, TILL_TABLET} from "../abstracts/variables";
import dwdCursor from "./custom-cursor";

export default class DwdFilter {
    static paged = 0; // Track pagination globally

    static init() {
        this.filterToggle();
        this.loadFiltersFromURL();
        this.setupEventListeners();
    }

    // Toggle filters on click
    static filterToggle() {
        document.querySelectorAll('.filter-toggle').forEach(toggle => {
            toggle.addEventListener('click', () => {
                toggle.classList.toggle('collapsed');
                toggle.classList.toggle('expanded');

                const filterContent = toggle.nextElementSibling;
                if (filterContent && filterContent.classList.contains('filter-content')) {
                    filterContent.classList.toggle('collapsed');
                    filterContent.classList.toggle('expanded');
                }
            });
        });
    }

    // Load filters from the URL on page load
    static loadFiltersFromURL() {
        const params = new URLSearchParams(window.location.search);
        const selectedFilters = {};

        params.forEach((value, key) => {
            if (!selectedFilters[key]) {
                selectedFilters[key] = [];
            }
            selectedFilters[key].push(value);

            const checkboxes = document.querySelectorAll(`input[name="${key}"][data-slug="${value}"]`);
            checkboxes.forEach(checkbox => {
                checkbox.checked = true;
            });
        });

        if (params.size >= 1) {
            this.applyFilters(selectedFilters);
        }
    }

    // Set up event listeners for filters and reset button
    static setupEventListeners() {
        this.setupFilterEventListeners();
        this.setupResetListener();
        this.setupLoadMoreListener();
    }

    // Set up event listeners for filter checkboxes
    static setupFilterEventListeners() {
        document.querySelectorAll('.filter-item__checkbox').forEach(checkbox => {
            checkbox.addEventListener('change', () => {
                this.paged = 0; // Reset paged when filters change
                this.updateAndApplyFilters();
            });
        });
    }

    // Update filters and apply them
    static updateAndApplyFilters() {
        const selectedFilters = this.getSelectedFilters();
        this.updateURL(selectedFilters);
        this.applyFilters(selectedFilters);
    }

    // Get currently selected filters
    static getSelectedFilters() {
        const selectedFilters = {};
        document.querySelectorAll('.filter-item__checkbox:checked').forEach(checkbox => {
            const filterName = checkbox.name;
            const filterValue = checkbox.getAttribute('data-slug');
            if (!selectedFilters[filterName]) {
                selectedFilters[filterName] = [];
            }
            selectedFilters[filterName].push(filterValue);
        });
        return selectedFilters;
    }

    // Update the URL with the selected filters
    static updateURL(selectedFilters) {
        const params = new URLSearchParams();

        for (let [key, values] of Object.entries(selectedFilters)) {
            values.forEach(value => params.append(key, value));
        }

        // Include the paged parameter in the URL
        if (this.paged > 0) {
            params.append('paged', this.paged);
        }

        const newURL = `${window.location.pathname}?${params.toString()}`;
        history.replaceState(null, '', newURL);
    }

    // Apply filters (this will trigger AJAX filtering)
    static applyFilters(selectedFilters) {
        console.log("Applying filters:", selectedFilters);
        this.ajaxFiltering(selectedFilters);
    }

    // AJAX request to filter content
    static async ajaxFiltering(selectedFilters) {
        let url = `/wp/wp-admin/admin-ajax.php`;

        const params = new URLSearchParams();
        params.append('action', 'filter_cases'); // Action name for the PHP function
        params.append('paged', this.paged); // Current pagination value

        // Append filters to the request
        for (let key in selectedFilters) {
            if (selectedFilters.hasOwnProperty(key)) {
                selectedFilters[key].forEach(value => {
                    params.append(`${key}[]`, value); // Use the array format
                });
            }
        }

        try {
            const response = await fetch(`${url}?${params.toString()}`, {
                method: 'GET',
            });
            const data = await response.json();

            if (data.success) {
                const casesWrapper = document.querySelector('.cases__wrapper');

                // Clear existing posts if filters are changed
                if (this.paged === 0) {
                    casesWrapper.innerHTML = ''; // Reset the overview
                }

                // Append the new HTML from the JSON response at the end of the wrapper
                casesWrapper.insertAdjacentHTML('beforeend', data.data.html);
                dwdCursor.refreshCursors(); // Refresh cursors after new posts are added
                if (data.data.has_next_page === false) {
                    document.querySelector('.filter__load-more').style.display = 'none';
                }
            } else {
                console.error('Error fetching filtered data:', data.data);
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        }
    }

    // Set up event listener for resetting filters
    static setupResetListener() {
        const resetButton = document.querySelector('.filter-reset');
        if (resetButton) {
            resetButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.resetFilters();
            });
        }
    }

    // Reset filters and update the URL
    static resetFilters() {
        document.querySelectorAll('.filter-item__checkbox').forEach(checkbox => {
            checkbox.checked = false;
        });
        this.paged = 0; // Reset paged on filters reset
        this.updateAndApplyFilters(); // Apply empty filters
    }

    // Set up event listener for "Load More Cases"
    static setupLoadMoreListener() {
        const loadMoreButton = document.querySelector('.filter__load-more');
        if (loadMoreButton) {
            loadMoreButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.paged++; // Increment paged
                this.updateAndApplyFilters(); // Fetch more data
            });
        }
    }
}