import Swiper from 'swiper';
import {Navigation, Pagination, Scrollbar, Autoplay} from 'swiper/modules';
import {FROM_TABLET, TILL_TABLET} from '../abstracts/variables';

export default class DwdSwiper {
    static init() {
        if (document.querySelector('.cases--slider')) {
            this.render_slider_default('.cases--slider', '.cases__wrapper', '.cases__item');
        }

        if (document.querySelector('.services-swiper')) {
            this.render_slider_services();
        }

        if (document.querySelector('.client-slider__swiper')) {
            this.render_slider_client();
        }

        if (document.querySelector('.featured-posts')) {
            this.render_slider_default('.featured-posts__slides', '.featured-posts__slide-wrapper', '.featured-posts__post');
        }
    }

    static addClasses(element, wrapperClass, itemClass) {
        if (element && wrapperClass && itemClass) {
            element.classList.add('swiper');

            const wrapper = element.querySelector(wrapperClass);
            if (wrapper) wrapper.classList.add('swiper-wrapper');

            const items = element.querySelectorAll(itemClass);
            items.forEach(item => item.classList.add('swiper-slide'));
        }
    }

    static removeClasses(element, wrapperClass, itemClass) {
        if (element && wrapperClass && itemClass) {
            element.classList.remove('swiper');

            const wrapper = element.querySelector(wrapperClass);
            if (wrapper) wrapper.classList.remove('swiper-wrapper');

            const items = element.querySelectorAll(itemClass);
            items.forEach(item => item.classList.remove('swiper-slide'));
        }
    }

    static swipers = []; // Array to hold all Swiper instances

    static render_slider_default(className, wrapperClass, itemClass) {
        // Prevent reinitializing the swiper if it already exists
        let existingSwiper = this.swipers.find(swiper => swiper.className === className);
        if (existingSwiper) return;

        let sliderClass = document.querySelector(className);
        this.addClasses(sliderClass, wrapperClass, itemClass);

        const menu = [
            {label: '1', color: 'red'},
            {label: '2', color: 'grey'},
            {label: '3', color: 'blue'}
        ];

        // Initialize Swiper and store the instance in the array
        const newSwiper = new Swiper(sliderClass, {
            modules: [Navigation, Pagination],
            direction: 'horizontal',
            loop: false,
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 40,
            centeredSlides: true,
            wrapperClass: 'swiper-wrapper',
            pagination: {
                el: '.dots',
                clickable: true,
                bulletActiveClass: 'active'
            }
        });

        // Store the new Swiper instance with its associated className
        this.swipers.push({ className, instance: newSwiper });

        // Bind the resize handler correctly
        window.addEventListener('resize', this.onResize.bind(this, className, wrapperClass, itemClass));

        // Initial call to handleResize
        this.handleResize(className, wrapperClass, itemClass);
    }

    static onResize(className, wrapperClass, itemClass) {
        // Handle the resize event
        this.handleResize(className, wrapperClass, itemClass);
    }

    static handleResize(className, wrapperClass, itemClass) {
        // Check the window width to determine if we need to destroy or create the slider
        if (window.innerWidth > TILL_TABLET) {
            this.destroy_slider_default(className, wrapperClass, itemClass);
        } else {
            // Only re-render if there isn't already an existing swiper instance
            if (!this.swipers.some(swiper => swiper.className === className)) {
                this.render_slider_default(className, wrapperClass, itemClass);
            }
        }
    }

    static destroy_slider_default(className, wrapperClass, itemClass) {
        let sliderClass = document.querySelector(className);
        this.removeClasses(sliderClass, wrapperClass, itemClass);

        // Find and destroy the swiper instance associated with the className
        const swiperIndex = this.swipers.findIndex(swiper => swiper.className === className);
        if (swiperIndex !== -1) {
            const swiperInstance = this.swipers[swiperIndex].instance;
            swiperInstance.destroy(true, true); // Destroy the Swiper instance
            this.swipers.splice(swiperIndex, 1); // Remove it from the array
        }

        // Clean up the resize event listener
        window.removeEventListener('resize', this.onResize.bind(this, className, wrapperClass, itemClass));
    }

    static render_slider_services() {
        const menu = [
            {label: 'Strategie', color: 'red'},
            {label: 'Techniek', color: 'grey'},
            {label: 'Online marketing', color: 'blue'}
        ];

        new Swiper('.services-swiper', {
            modules: [Navigation, Pagination],
            direction: 'horizontal',
            loop: false,
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 24,
            centeredSlides: true,
            wrapperClass: 'services-swiper-wrapper',
            pagination: {
                el: '.custom-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return `<h4 class="${className} ${menu[index].color} alignfull">${menu[index].label}</h4>`;
                },
                bulletActiveClass: 'active'
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
        });
    }

    static render_slider_client() {
        const menu = [
            {label: '1', color: 'red'},
            {label: '2', color: 'grey'},
            {label: '3', color: 'blue'}
        ];

        const clientSwiper = new Swiper('.client-slider__swiper', {
            modules: [Navigation, Pagination, Autoplay],
            direction: 'horizontal',
            loop: false,
            spaceBetween: 0,
            autoplay: {
                delay: 5000
            },
            pagination: {
                el: '.client-slider__mobile-pagination',
                clickable: true,
                bulletActiveClass: 'active'
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    pagination: {
                        el: '.client-slider__mobile-pagination',
                        clickable: true,
                        renderBullet: function (index, className) {
                            // Use the menu and modulo operator to cycle through the menu items
                            const menuItem = menu[index % menu.length];
                            return `
                            <span class="${className} ${menuItem.color} alignfull" style="color:${menuItem.color};">
                                ${menuItem.label}
                            </span>`;
                        },
                        bulletActiveClass: 'active'
                    }
                },
                1199: {slidesPerView: 1}
            },
            wrapperClass: 'client-slider__wrapper'
        });

        const clientListItems = Array.from(document.querySelectorAll('.client-slider__pagination-bullet'));
        const activeBulletSelector = '.client-slider__pagination-wrapper .swiper-pagination-bullet.active';
        let activeBullet = document.querySelector(activeBulletSelector);

        const changeActive = (item) => {
            if (activeBullet) activeBullet.classList.remove('active');
            item.classList.add('active');
            activeBullet = item;
        };

        clientListItems.forEach((item, index) => {
            item.addEventListener('mouseover', () => {
                clientSwiper.slideTo(index);
                changeActive(item);
            });
        });

        clientSwiper.on('slideChange', () => {
            changeActive(clientListItems[clientSwiper.activeIndex]);
        });
    }
}
