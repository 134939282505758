import { FROM_DESKTOP } from '../abstracts/variables';

export default class DwdConsultation {
    static init () {
        this.addListeners();
        this.moveFormOnMobile();
    }

    static addListeners () {
        document.querySelector('.consultation__sticky .wp-block-button__link')?.addEventListener('click', function (e) {
            e.preventDefault();

            const form = document.querySelector('.consultation__banner .consultation__sticky-form');

            if (form) {
                this.classList.toggle('open');
                form.classList.toggle('consultation__sticky-form--show');
            }
        });
        window.addEventListener('resize', () => {
            this.moveFormOnMobile();
        });
    }

    static moveFormOnMobile () {
        let form;

        if (window.matchMedia('(min-width: ' + FROM_DESKTOP + 'px)').matches) {
            form = document.querySelector('.consultation__sticky > .consultation__sticky-form');
        } else {
            form = document.querySelector('.consultation__banner > .consultation__sticky-form');
        }

        if (!form) {
            return;
        }

        const clone = form.cloneNode(true);

        if (window.matchMedia('(min-width: ' + FROM_DESKTOP + 'px)').matches) {
            document.querySelector('.consultation__banner').insertBefore(clone, document.querySelector('.consultation__content'));
        } else {
            document.querySelector('.consultation__sticky').appendChild(clone);
        }

        form.remove();
    }
}